import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import {
    Button
} from '@material-ui/core';
import { useSteam, deletedArticle } from '../../utils/initFirebase';
import Snack from '../../metaComponents/snack';

const Delete = (props) => {

    const [state, dispatch] = useSteam();
    const [error, setError] = useState(false);
    const [deleted, setDeleted] = useState(false);

    console.log(props.history);

    const deleteArticle = () => {
        try {
            state.fire.firestore().collection('blog').doc(props.id).delete();
            setDeleted(true);
        } catch (e) {
            console.log(e);
            setError(true);
        }
    }
    if (deleted) {
        setTimeout(() => dispatch(deletedArticle(props.id)), 0);
        return <Redirect to="/admin/blog" />
    }
    return (
        <>
            {
                (error)
                ?
                    <Snack
                        variant="danger"
                        message="Error deleting Article!"
                        outerOpen={setError}
                    />
                :
                    null
            }
            <Button
                variant="contained"
                color="danger"
                onClick={() => deleteArticle()}
            >
                Delete
            </Button>
        </>
    );
}

export default Delete;