import React from 'react';
import { Link } from 'react-router-dom';
import {
    Card,
    CardMedia,
    CardContent,
    Typography,
    makeStyles,
    Grid,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    article: {
        margin: '50px auto',
    },
    imageArticle: {
        height: 'auto',
        width: 'auto',
        maxHeight: '280px',
        maxWidth: '280px',
    },
    headerText: {
        padding: '20px',
    },
    infoText: {
        padding: '20px',
    },
    articleLink: {
        textDecoration: 'none'
    },
}));

const ArticleList = (props) => {
    const baseURL = (props.admin) ? '/admin/blog/edit/' : '/blog/';
    const classes = useStyles();
    const list = Object.entries(props.articles).map((entry) => {
        const article = entry[1];
        const image = (article.image) ? 
            <img 
                className={classes.imageArticle}
                src={article.image} 
                alt={article.image}
            /> : null;
        return (
            <Grid item xs={12} sm={6} lg={4}>
                <Card 
                    id={article.id}
                    className={classes.article}
                >
                    <CardContent>
                        <Link
                            to={baseURL + `${entry[0]}`}
                            className={classes.articleLink}
                        >
                            <Typography
                                variant="h6"
                                component="h3"
                            >
                                {article.title}
                            </Typography>
                        </Link>
                        <Typography
                            color="textSecondary" 
                            gutterBottom
                        >
                            {article.subtitle}
                        </Typography> 
                        <CardMedia>
                            {image}
                        </CardMedia>
                    </CardContent>
                </Card>
            </Grid>
        );
    });

    return (
        <Grid container>
            {list}
        </Grid>
    );
};

export default ArticleList;