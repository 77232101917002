import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
    AppBar,
    Tabs,
    Tab,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    label: {
        fontSize: '1.2em',
        marginLeft: 'auto',
    }
}));

const WebNavBar = (props) => {
    const classes = useStyles();
    const [tab, setTab] = useState(0);
    return (
        <div>
            <AppBar
                position="static"
                color="default"
            >
                <Tabs
                    value={tab}
                    onChange={setTab}
                    indicatorColor="default"
                    textColor="default"
                    variant="width"
                >
                    <NavLink to="/home" style={{ textDecoration: 'none' }}>
                        <Tab label="Home" />
                    </NavLink>
                    <NavLink to="/about" style={{ textDecoration: 'none' }}>
                        <Tab label="About" />
                    </NavLink>
                    <NavLink to="/blog" style={{ textDecoration: 'none' }}>
                        <Tab label="Blog" />
                    </NavLink>
                    <NavLink to="/contact" style={{ textDecoration: 'none' }}>
                        <Tab label="Contact" />
                    </NavLink>
                    <Tab
                        className={classes.label}
                        label="NJL"
                        color="default"
                    />
                </Tabs>
            </AppBar>
        </div>
    );
};

export default WebNavBar;