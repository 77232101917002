import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { CircularProgress, Button, makeStyles, TextField } from '@material-ui/core';
import { AddCircleOutline } from '@material-ui/icons';
import { useSteam, gotArticles } from '../utils/initFirebase';
import ArticleList from './articleList';

const useStyles = makeStyles(theme => ({
    loading: {
        margin: '30px auto',
        display: 'block',
    },
    navActions: {
        display: 'flex',
        margin: '30px',
        justifyContent: 'flex-start',
    },
    addArticle: {
        height: '50px',
        width: '50px',
        marginRight: '20px',
    },
}));

const AdminBlog = (props) => {
    const classes = useStyles();
    const [state, dispatch] = useSteam();

    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [articles, setArticles] = useState(state.articles);

    useEffect(() => {
        if (search.length > 2) {
            const rex = new RegExp(`${search}`, 'i');
            let arts = Object.entries(state.articles)
                .filter((art) => {
                    for (let tag of art[1].tags) {
                        if (rex.test(tag)) return true;
                    }
                    if (rex.test(art[1].title) || rex.test(art[1].subtitle)) {
                        return true;
                    }
                    return false
                })
                .reduce((a, [id, art], l) => {
                    console.log(l);
                    a[id] = art;
                    return a;
                }, {});
                setArticles(arts);
        } else {
            setArticles(state.articles);
        }

    }, [search, state.articles]);

    if (error) return <Redirect to="/500" />;

    const getBlog = async () => {
        const arts = {};
        const db = state.fire.firestore();
        const blogRef = db.collection('blog');
        try {
            const snapshot = await blogRef.get();
            if (!snapshot.empty) {
                snapshot.forEach((doc) => {
                    arts[doc.id] = doc.data();
                });
            }
            dispatch(gotArticles(arts));
        } catch (e) {
            setError(true);
        }
    }

    if (!state.allArticles) {
        if (!loading) {
            getBlog();
            setLoading(true);
        }
        return <CircularProgress className={classes.loading} />
    }

    if (loading) {
        setLoading(false);
    }
    return (
        <div>
            <div className={classes.navActions}>
                <Button>
                    <AddCircleOutline
                        className={classes.addArticle}
                        aria-label="Add Article"
                        onClick={() => props.history.push('/admin/blog/edit')}
                    />
                </Button>
                <TextField
                    value={search}
                    variant="outlined"
                    label="Search"
                    onChange={(e) => setSearch(e.target.value)}
                />
            </div>
            <ArticleList admin={true} articles={articles} />
        </div>
    );
};

export default AdminBlog;