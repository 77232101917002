import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Typography } from '@material-ui/core/';
import MobileNavBar from './metaComponents/mobile_navbar';
import WebNavBar from './metaComponents/web_navbar';
import Home from './home/home';
import About from './about/about';
import Contact from './contact/contact';
import EditBlog from './blog/editblog';
import Editor from './blog/blogEditor/editor';
import Blog from './blog/blog';
import Article from './blog/article';
import ErrorDOM from './errorCode/serverError';
import NotFound from './errorCode/notFound';
import SubSuc from './blog/sub/subSuc';
import UnSubSuc from './blog/sub/unSubSuc';
import { useSteam, setUser } from './utils/initFirebase';

const useStyles = makeStyles(theme => ({
  footer: {
    position: 'relative',
    bottom: '0',
    margin: 'auto auto 0 auto',
    textAlign: 'center',
    width: '70%',
  }
}));

function App() {
  const classes = useStyles();
  const theme = useTheme();
  const mediaSize = useMediaQuery(theme.breakpoints.up('sm'))

  const [state, dispatch] = useSteam();
  setTimeout(() => {
    state.fire.auth().onAuthStateChanged((user) => {
      if (!state.user && user) {
        dispatch(setUser(user));
      }
    });
  }, 0);

  return (
    <div>
      <Route path="/" component={(mediaSize) ? WebNavBar : MobileNavBar} />
      <Switch>
        <Route exact path="/500" component={ErrorDOM} />
        <Route exact path="/404" component={NotFound} />
        <Route exact path="/" component={Home} />
        <Route path="/home" component={Home} />
        <Route path="/about" component={About} />
        <Route exact path="/blog" component={Blog} />
        <Route path="/blog/subscribe/success" component={SubSuc} />
        <Route path="/blog/subscribe/error" render={() => <Redirect to="/500" />} />
        <Route path="/blog/unsubscribe/success" component={UnSubSuc} />
        <Route path="/blog/unsubscribe/error" render={() => <Redirect to="/500" />} />
        <Route path="/blog/:id" component={Article} />
        <Route path="/contact" component={Contact} />
        <Route exact path="/admin/blog" component={EditBlog} />
        <Route exact path="/admin/blog/edit" component={Editor} />
        <Route path="/admin/blog/edit/:id" component={Editor} />
      </Switch>
      <div className={classes.footer}>
        <hr />
        <Typography>
          <i>Niklas Jona Lohmann</i>
          <br />
          <i>Copyrights 2021</i>
        </Typography>
      </div>
    </div>
  );
}

export default App;
