import React, { useState } from 'react';
import {
    Button,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    makeStyles,
} from '@material-ui/core';
import { Email } from '@material-ui/icons';
import Snack from '../../metaComponents/snack';

const useStyles = makeStyles(theme => ({
    dialogActions: {
        justifyContent: 'space-between',
    },
}));

const SubBlog = (props) => {
    const classes = useStyles();

    const [showSub, setShowSub] = useState(false);
    const [sub, setSub] = useState(false)
    const [subError, setSubError] = useState(false);
    const [unSub, setUnSub] = useState(false);
    const [unSubError, setUnSubError] = useState(false);
    const [subEmail, setSubscribeEmail] = useState('');

    return (
        <>
            <Button
                onClick={() => setShowSub(true)} 
            >
                <Email />
                Subscription
            </Button>
        {
            (showSub)
            ?
                <Dialog
                    open={showSub} 
                >
                    <DialogTitle>
                        Subscribe
                    </DialogTitle>
                    <DialogContent>
                    <TextField
                        label="E-mail"
                        value={subEmail}
                        onChange={(e) => setSubscribeEmail(e.target.value)}
                    />
                    <DialogContentText>
                        I agree in case of subscription to receive e-mails 
                        for notifications of new articles on this website 
                        and to permit this information to the host.
                    </DialogContentText>
                    <DialogActions className={classes.dialogActions}>
                        <Button
                            variant="contained"
                            onClick={() => {
                                setShowSub(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                setShowSub(false);
                                fetch(`https://us-central1-njl-portfolio-gcp.cloudfunctions.net/requestUnSub?email=${subEmail}`)
                                    .then((r) => {
                                        console.log(r);
                                        if (r.ok) {
                                            setUnSub(true);
                                        } else {
                                            setUnSubError(true);
                                        }
                                    })
                                    .catch((e) => {
                                        console.log(e);
                                        setUnSubError(true);
                                    });
                                setSubscribeEmail('');
                            }}
                        >
                            Unsubscribe
                        </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    setShowSub(false);
                                    fetch(`https://us-central1-njl-portfolio-gcp.cloudfunctions.net/addSub?email=${subEmail}`)
                                        .then((r) => {
                                            console.log(r)
                                            if (r.ok) {
                                                setSub(true);
                                            } else {
                                                console.log(r.ok);
                                                setSubError(true);
                                            }
                                        })
                                        .catch((e) => {
                                            console.log(e);
                                            setSubError(true);
                                        })
                                    setSubscribeEmail('');
                                }}
                            >
                                Subscribe
                            </Button>
                    </DialogActions>

                    </DialogContent>
                </Dialog>
            :
                null
        }
        {
            (sub)
            ?
                <Snack
                    message="Subscribe confirmation is sent to you!"
                    variant="success"
                    outerOpen={setSub}
                />
            :
                (subError)
                ?
                    <Snack
                        message="Error sending request!"
                        variant="danger"
                        outerOpen={setSubError}
                    />
                :
                    null

        }
        {
            (unSub)
            ?
                <Snack
                    message="Unsubscribe request is sent to you!"
                    variant="success"
                    outerOpen={setUnSub}
                />
            :
                (unSubError)
                ?
                    <Snack
                        message="Error sending request!"
                        variant="danger"
                        outerOpen={setUnSubError}
                    />
                :
                    null
        }
        </>
    );
};

export default SubBlog;