import React from 'react';
import {
    Typography,
    makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    removalText: {
        margin: '20px auto',
        textAlign: 'center',
    },
}));

const UnSubSuc = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.removalText}>
            <Typography
                variant="h1"
            >
                Goodbye
            </Typography>
            <Typography
                variant="h2"
            >
                Someday we'll meet again. Here is always a place for you.
            </Typography>
        </div>
    );
};

export default UnSubSuc;