import React, { useState } from 'react';
import { useSteam } from '../../utils/initFirebase';
import {
    makeStyles,
    Button,
    TextField,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Chip,
    Checkbox,
    FormControlLabel,
} from '@material-ui/core';
import Snack from '../../metaComponents/snack';

const useStyles = makeStyles(theme => ({
    input: {
        display: 'block',
        margin: '20px',
    },
    saveButton: {
        margin: 'auto 20px',
    },
}));



export const Save = (props) => {
    const classes = useStyles();
    const [show, setShow] = useState(false);

    const [state] = useSteam();

    const [title, setTitle] = useState((props.id) ? state.articles[props.id].title : '');
    const [subtitle, setSubtitle] = useState((props.id) ? state.articles[props.id].subtitle : '');
    const [image, setImage] = useState((props.id) ? state.articles[props.id].image : '');
    const [tags, setTags] = useState((props.id) ? state.articles[props.id].tags : []);
    const [tag, setTag] = useState('');
    const [createdAt, setCreatedAt] = useState((props.id) ? state.articles[props.id].createdAt : new Date(Date.now()).toLocaleDateString());
    const [isPublic, setIsPublic] = useState((props.id) ? state.articles[props.id].isPublic : false);

    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    /*
    const login = async () => {
        try {
            const provider = new state.fire.auth.GoogleAuthProvider();
            await state.fire.auth().signInWithPopup(provider);
            /*
            const token = r.credential.accessToken;
            const user = r.user;
            
        } catch (e) {
            console.log(e);
        }
        await saveArticle();
    };
    */

    const saveArticle = async () => {
        const db = state.fire.firestore();
        try {
            if (props.id) {
                await db.collection('blog').doc(props.id).set({
                    title,
                    subtitle,
                    image,
                    tags,
                    createdAt,
                    isPublic,
                    content: props.content,
                });
            } else {
                await db.collection('blog').add({
                    title,
                    subtitle,
                    image,
                    tags,
                    createdAt,
                    isPublic,
                    content: props.content,
                    likes: 0,
                });
            }
            setSuccess(true);
            setTimeout(() => setSuccess(false), 5000);
        } catch (e) {
            console.log(e);
            setError(true);
                setTimeout(() => setError(false), 5000);
        }
    }

    return (
        <>
            {(success)
            ?
                <Snack
                    variant="success"
                    message="Article Saved!"
                />
            : 
                (error)
                ?
                    <Snack
                        variant="danger"
                        message="Error saving article!"
                    />
                : null
            }
            <Button
                className={classes.saveButton}
                variant="contained"
                color="primary"
                onClick={() => setShow(true)}
            >
                Save
            </Button>
            <Dialog
                open={show}
                onClose={() => setShow(false)}
                aria-labelledby="Enter Article Data"
            >
                <DialogTitle>Save</DialogTitle>
                <DialogContent>
                    <TextField
                        className={classes.input}
                        label="Title"
                        variant="outlined"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                    <TextField
                        className={classes.input}
                        label="Subtitle"
                        variant="outlined"
                        value={subtitle}
                        onChange={(e) => setSubtitle(e.target.value)}
                    />
                    <TextField
                        className={classes.input}
                        label="Image Link"
                        variant="outlined"
                        value={image}
                        onChange={(e) => setImage(e.target.value)}
                    />
                    <TextField
                        className={classes.input}
                        label="Tags"
                        variant="outlined"
                        value={tag}
                        onChange={(e) => setTag(e.target.value)}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                setTags([...tags, tag]);
                                setTag('');
                            }
                        }}
                    />
                    <Button
                        className={classes.input}
                        variant="outlined"
                        onClick={() => {
                            setTags([...tags, tag]);
                            setTag('');
                        }}
                    >
                        Add
                    </Button>
                    {tags.map((t, i, arr) => {
                        return (
                            <Chip 
                                key={t + arr} 
                                label={t}
                                onDelete={() => {
                                    const at = tags.filter((_, index) => index !== i);
                                    setTags(at);
                                }}
                            >
                                {t}
                            </Chip>
                        );
                    })}
                    <TextField
                        className={classes.input}
                        label="Date"
                        variant="outlined"
                        value={createdAt}
                        onChange={(e) => setCreatedAt(e.target.value)}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isPublic}
                                onChange={() => setIsPublic(!isPublic)}
                                inputProps={{ 'aria-label': 'Public' }}
                            />
                        }
                        label="Public"
                    />
                </DialogContent>
                <DialogActions>
                    <Button 
                        variant="contained"
                        color="danger"
                        onClick={() => setShow(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setShow(false);
                            saveArticle();
                        }}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};