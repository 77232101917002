import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import {
    Paper,
    Divider,
    Typography,
    CircularProgress,
    TextField,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useSteam, gotArticles } from '../utils/initFirebase';
import ArticleList from './articleList';
import SubBlog from './sub/subBlog';

const useStyles = makeStyles(theme => ({
    wrapper: {
        [theme.breakpoints.up('sm')]: {
            margin: '100px auto',
            maxWidth: '942px',
        },
        margin: '30px auto',
    },
    headerText: {
        padding: '20px',
    },
    infoText: {
        padding: '20px',
    },
    loading: {
        margin: '30px auto',
        display: 'block',
    },
    blogHeader: {
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            justifyContent: 'space-between',
        },
    },
    inputSearch: {
        margin: '20px',
    },
    headerActions: {
        display: 'flex',
        alignItems: 'center',
    },
    dialogActions: {
        'justifyContent': 'space-between',
    },
}));

const Blog = (props) => {
    const theme = useTheme();
    const classes = useStyles(theme);

    const [state, dispatch] = useSteam();
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [articles, setArticles] = useState(state.articles);

    useEffect(() => {
        if (search.length > 2) {
            const rex = new RegExp(`${search}`, 'i');
            let arts = Object.entries(state.articles)
                .filter((art) => {
                    for (let tag of art[1].tags) {
                        if (rex.test(tag)) return true;
                    }
                    if (rex.test(art[1].title) || rex.test(art[1].subtitle)) {
                        return true;
                    }
                    return false
                })
                .reduce((a, [id, art], l) => {
                    console.log(l);
                    a[id] = art;
                    return a;
                }, {});
            setArticles(arts);
        } else {
            setArticles(state.articles);
        }

    }, [search, state.articles]);

    if (error) return <Redirect to="/500" />;

    const getBlog = async () => {
        const arts = {};
        const db = state.fire.firestore();
        const blogRef = db.collection('blog');
        try {
            const snapshot = await blogRef.where('isPublic', '==', true).get();
            if (!snapshot.empty) {
                snapshot.forEach((doc) => {
                    arts[doc.id] = doc.data();
                });
            }
            dispatch(gotArticles(arts));
            setArticles(arts);
        } catch (e) {
            console.log(e);
            setError(true);
        }
    }

    if (!state.allArticles) {
        if (!loading) {
            getBlog();
            setLoading(true);
        }
        return <CircularProgress className={classes.loading} />
    }

    if (loading) {
        setLoading(false);
    }


    return (
        <div className={classes.wrapper}>
            <Paper>
                <Typography
                    className={classes.headerText}
                    variant="h6"
                >
                    Blog
                </Typography>
                <Divider />
                <div className={classes.blogHeader}>
                    <Typography
                        className={classes.infoText}
                    >
                        This is still under development
                        <br />
                        and has bugs on the editor side.
                        <br />
                        But if you can't wait take a look at <a href="https://n-0-0-n.medium.com">this</a>.
                        <br />
                    </Typography>
                    <div className={classes.headerActions}>
                        <SubBlog />
                        <TextField
                            className={classes.inputSearch}
                            variant="outlined"
                            label="Search Article"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </div>
                </div>
            </Paper>
            <ArticleList articles={articles} />
        </div>
    );
}

export default Blog; 