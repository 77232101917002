import React, { useState, useEffect } from 'react';
import {
    Paper,
    Typography,
    Divider,
    Button,
    TextField,
    Avatar,
    SnackbarContent,
    IconButton,
} from '@material-ui/core';
import { Clear, CheckCircleOutline, Error as ErrorIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useSteam } from '../utils/initFirebase';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    wrapper: {
        [theme.breakpoints.up('sm')]: {
            margin: '100px auto',
            maxWidth: '942px',
        },
        margin: '30px auto',
    },
    alertSnack: {
        position: 'absolute',
        left: '10px',
        bottom: '10px',
        maxWidth: '200px',
    },
    alertText: {
        marginLeft: '10px',
    },
    alertLeft: {
        display: 'flex',
        alignItems: 'center',
    },
    successAlert: {
        backgroundColor: 'green',
    },
    dangerAlert: {
        backgroundColor: 'red',
    },
    paper: {
        margin: '10px auto 10px auto',
    },
    headerText: {
        padding: '20px',
    },
    infoText: {
        padding: '20px',
    },
    avatarWrapper: {
        display: 'flex',
        justifyContent: 'center',
    },
    avatar: {
        padding: '10px',
        margin: 'auto',
        width: '100px',
        height: '100px',
    },
    input: {
        margin: '20px',
    },
    sendButton: {
        margin: '20px',
    },
    listNetwork: {
        listStyle: 'none',
        padding: '20px 0 20px 20px',
    },
    listIcon: {
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        width: '20px',
        height: '20px',
    },
    iconText: {
        marginLeft: '10px',
    },
}));

const CustomSnackbar = ({ classes, onClose, IconForStatus, message }) => {
    let classText;
    if (message === 'Message sent!') {
        classText = clsx(classes.successAlert, classes.alertSnack);
    } else {
        classText = clsx(classes.dangerAlert, classes.alertSnack);
    }
    setTimeout(onClose, 6000);
    return (
        <SnackbarContent
            className={classText}
            message={
                <span id="status notification" className={classes.alertLeft}>
                    <IconForStatus />
                    <p className={classes.alertText}>
                        {message}
                    </p>
                </span>
            }
            action={[
                <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
                    <Clear />
                </IconButton>
            ]}
        />
    );
}


const Contact = (props) => {
    const classes = useStyles();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [sendStatus, setSendStatus] = useState(); //error || success
    const [sent, setSent] = useState() //true || false
    const [profilePictureUrl, setProfilePictureUrl] = useState('');
    const [state] = useSteam();
    const closeSnack = () => {
        setSent(false);
        setSendStatus();
        setName('');
        setEmail('');
        setMessage('');
    }
    const SuccessSnack = <CustomSnackbar
        classes={classes}
        onClose={closeSnack}
        IconForStatus={CheckCircleOutline}
        message={'Message sent!'}
    />;
    const ErrorSnack = <CustomSnackbar
        classes={classes}
        onClose={closeSnack}
        IconForStatus={ErrorIcon}
        message={'Error sending Message!'}
    />;
    const sendMsg = async () => {
        const sendMessage = state.fire.functions().httpsCallable('sendMessage');
        try {
            await sendMessage({ text: message, sender: email, name: name });
            setSendStatus('success');
            setSent(true);

        } catch (e) {
            console.log(e);
            setSendStatus('error')
            setSent(true);
        }
    }

    const getProfilePicture = async () => {
        const storage = state.fire.storage();
        const storageRef = storage.ref();
        const url = await storageRef.child('niklas-jona-lohmann.1024x1024.jpg').getDownloadURL();
        setProfilePictureUrl(url);
    }
    useEffect(() => getProfilePicture());
    return (
        <div className={classes.wrapper}>
            <Paper className={classes.paper}>
                <Typography
                    variant="h4"
                    component="h3"
                    className={classes.headerText}
                >
                    Contact
                </Typography>
                <Divider />
                <Typography className={classes.infoText}>
                    Please leave a message if you find
                <br />
                my services are of use to you.
                <br />
                I am happy about
                <br />
                any feedback or personal
                <br />
                recommendations. Furthermore
                <br />
                you can find here linked
                <br />
                profiles from other platforms.
                <br />
                So don't hesitate to connect with me.
                <br />
                </Typography>
                <form>
                    <div>
                        <TextField
                            label="Name"
                            className={classes.input}
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                            variant="outlined"
                        />
                        <TextField
                            label="Email"
                            className={classes.input}
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                            variant="outlined"
                        />
                    </div>
                    <div>
                        <TextField
                            className={classes.input}
                            label="Message"
                            variant="outlined"
                            value={message}
                            onChange={(event) => setMessage(event.target.value)}
                            rowsMax={10}
                            margin="normal"
                            multiline
                        />
                    </div>
                    <Button
                        className={classes.sendButton}
                        variant="outlined"
                        color="primary"
                        onClick={() => sendMsg()}
                    >
                        Send
                        </Button>
                </form>
            </Paper>
            <Paper className={classes.paper}>
                <ul className={classes.listNetwork}>
                    <li className={classes.listIcon}>
                        <a href="https://linkedin.com/in/niklas-jona-lohmann-55b24816a">
                            <img
                                alt="LinkedIn icon"
                                className={classes.icon}
                                src="https://image.flaticon.com/icons/svg/174/174857.svg"
                            />
                        </a>
                        <span className={classes.iconText}>LinkedIn</span>
                    </li>
                    <li className={classes.listIcon}>
                        <a href="https://github.com/n-0">
                            <img
                                alt="GitHub icon"
                                className={classes.icon}
                                src="https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png"
                            />
                        </a>
                        <span className={classes.iconText}>GitHub</span>
                    </li>
                    <li className={classes.listIcon}>
                        <a href="https://www.hackerrank.com/niklasjona00">
                            <img
                                alt="HackerRank icon"
                                className={classes.icon}
                                src="https://hrcdn.net/fcore/assets/brand/h_mark_sm-966d2b45e3.svg"
                            />
                        </a>
                        <span className={classes.iconText}>HackerRank</span>
                    </li>
                </ul>
            </Paper>
            <Paper className={clsx(classes.avatarWrapper, classes.paper)}>
                <div>
                    <Typography
                        variant="h6"
                        component="h3"
                    >
                        Or talk to this guy if you see him.
                    </Typography>
                    <Avatar
                        className={classes.avatar}
                        alt="Niki"
                        src={profilePictureUrl}
                    />
                </div>
            </Paper>
            {
                (sent) ? (sendStatus === 'success') ? SuccessSnack : ErrorSnack : null
            }
        </div>
    );
};

/*
    <li className={classes.listIcon}>
        <a href="https://www.dpart.net">
            <img
                alt="Pawn icon"
                className={classes.icon}
                src="https://img.icons8.com/cotton/2x/pawn.png"
            />
        </a>
        <span className={classes.iconText}>Own Chess Platform</span>
    </li>
*/

export default Contact;
