import React from 'react';
import { Paper, Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Emo from './emo';

const useStyles = makeStyles(theme => ({
    wrapper: {
        [theme.breakpoints.up('sm')]: {
            margin: '100px auto',
            width: '942px',
        },
        margin: '30px auto',
    },
    header: {
        textAlign: 'center',
    },
    headerText: {
        padding: '20px',
    },
    paper: {
        marginTop: '30px',
        marginBottom: '30px',
    },
    infoText: {
        padding: '20px',
    }
}));


const Home = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.wrapper}>
            <Paper className={classes.paper}>
                <div>
                    <Typography
                        variant="h4"
                        component="h3"
                        className={classes.headerText}>
                        Home
                        </Typography>
                    <Divider />
                    <Typography
                        component="p"
                        className={classes.infoText}
                    >
                        Welcome to my website.
                            <br />
                            I am your host
                            <br />
                            Niklas Jona Lohmann or just Niki.
                            <br />
                            You can find here some information about me like
                            <br />
                            Projects, Experience and a Blog.
                            <br />
                            Also there is a difficult game right below this,
                            <br />
                            where you can learn about the names
                            <br />
                            of your favorite emojis.
                            <br />
                            Enjoy!
                        </Typography>
                </div>
            </Paper>
            <Emo />
        </div>
    );
}

export default Home;