import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    error: {
        textAlign: 'center',
    },
}));

const ErrorDOM = () => {
    const classes = useStyles();
    return (
        <div className={classes.error}>
            <h1>500 Internal Server Error</h1>
            <a href="/home">Back Home</a>
        </div>
    );
}

export default ErrorDOM;