import React from 'react';
import {
    Paper,
    makeStyles,
} from '@material-ui/core';
import {
    FontVariant,
    FontStyle,
    Alignment,
    HRLine,
    AddImage,
    CreateLink,
    FontSize,
    FontColor,
    FontFamily,
    OrderedList,
    BulletList,
} from './tools';

const useStyles = makeStyles(theme => ({
    toolBar: {
        maxWidth: '942px',
        margin: '30px auto',
        padding: '20px 0 20px 0',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
    }
}));

export const Bar = (props) => {
    const classes = useStyles();
    return (
        <Paper className={classes.toolBar}>
            <FontVariant />
            <FontFamily />
            <FontStyle />
            <Alignment />
            <AddImage />
            <CreateLink />
            <OrderedList />
            <BulletList />
            <HRLine />
            <FontSize />
            <FontColor />
        </Paper>
    );
};