import React from 'react';
import {
    Drawer,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Divider,
} from '@material-ui/core';
import {
    Notes as NotesIcon,
    FontDownload,
    Contacts,
    Home,
    Clear,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { NavLink } from 'react-router-dom';


const points = ['Home', 'About', 'Blog', 'Contact'];
const icons = [
    <Home />,
    <FontDownload />,
    <NotesIcon />,
    <Contacts />
];
const useStyle = makeStyles((theme => ({
    appBar: {
        zIndex: 1,
    },
    drawer: {
        width: 240,
        flexShrink: 0,
    },
    drawerPaper: {
        width: 240,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    clearButton: {
        padding: '10px',
    }
})));

const MobileMenu = (props) => {
    const classes = useStyle();
    const pages = points.map((page, index) => {
        return (
            <NavLink
                to={`/${page.toLowerCase()}`}
                style={{ textDecoration: 'none' }}
            >
                <ListItem
                    button={page}
                    key={page + index}
                    onClick={() => props.setMenu(false)}
                >
                    <ListItemIcon>{icons[index]}</ListItemIcon>
                    <ListItemText primary={page} />
                </ListItem>
            </NavLink>
        );
    });
    return (
        <Drawer
            className={classes.drawer}
            classes={{
                paper: classes.drawerPaper
            }}
            open={props.menuState}
        >
            <div className={classes.drawerHeader}>
                <Clear
                    className={classes.clearButton}
                    onClick={() => {
                        props.setMenu(false);
                    }}
                />
            </div>
            <Divider />
            <List>
                {pages}
            </List>
        </Drawer>
    );
}

export default MobileMenu;