import React, { useState } from 'react';
import {
    Button,
    makeStyles,
} from '@material-ui/core';
import { useSteam, setUser, ADMIN_LOGIN } from '../utils/initFirebase';
import Snack from '../metaComponents/snack';
import AdminBlog from './adminBlog';

const useStyles = makeStyles(theme => ({
    buttonLogin: {
        display: 'block',
        margin: '50px auto',
    },
}));

const AdminLogin = (props) => {
    const classes = useStyles();
    const [state, dispatch] = useSteam();
    const [error, setError] = useState(false);
    const provider = new state.fire.auth.GoogleAuthProvider();
    const getUser = async (provider) => {
        try {
            let r = await state.fire.auth().signInWithPopup(provider);
            r = await state.fire.auth().getRedirectResult();
            console.log(r);
            if (r.credential) {
                r.user.credential = r.credential;
            }
            dispatch(setUser(r.user));
            if (r.user.auth && r.user.mail === process.env.REACT_APP_ADMIN_MAIL) {
                dispatch({ type: ADMIN_LOGIN });
            }
        } catch (e) {
            setError(true)
            setTimeout(() => setError(false), 5000);
            console.log('Login Error', e);
        }
    }
    return (
        <>
            {
                (error)
                    ?
                    <Snack
                        variant="danger"
                        message="Login Error!"
                    />
                    :
                    null
            }
            <Button
                className={classes.buttonLogin}
                variant="outlined"
                color="primary"
                onClick={() => getUser(provider)}
            >
                Login
        </Button>
        </>
    );
};

const Edit = (props) => {
    const [state] = useSteam();
    if (state.user) {
        return (
            <AdminBlog history={props.history} />
        );
    } else {
        return (
            <AdminLogin />
        );
    }
}

export default Edit;