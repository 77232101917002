import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import {
    Paper,
    Divider,
    Button,
    CircularProgress,
} from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useSteam, gotArticles, actionLike } from '../utils/initFirebase';

const useStyles = makeStyles(theme => ({
    wrapper: {
        [theme.breakpoints.up('sm')]: {
            margin: '100px auto',
            maxWidth: '942px',
        },
        margin: '30px auto',
    },
    paper: {
        margin: '10px auto 10px auto',
    },
    headerText: {
        padding: '20px',
    },
    infoText: {
        padding: '20px',
    },
    buttonLike: {
        margin: '20px',
    },
    buttonBack: {
        marginBottom: '20px',
        marginLeft: '10px',
    },
    wrapperImage: {
        margin: '20px auto',
    },
    imageArticle: {
        maxWidth: '100%',
        maxHeight: '100%',
    },
    loading: {
        margin: '30px auto',
        display: 'block',
    },
    article: {
        padding: '50px',
    },
}));


const Article = (props) => {
    const theme = useTheme();
    const classes = useStyles(theme);

    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [notFound, setNotFound] = useState(false);
    const [state, dispatch] = useSteam();

    const { params }  = props.match;
    const articleId = params.id;

    if (error) return <Redirect to="/500" />;
    if (notFound) return <Redirect to="/404" />;

    const getArticle = async () => {
        const db = state.fire.firestore();

        try {
            const doc = await db.collection('blog').doc(articleId).get();
            console.log('got docs', doc);
            if (!doc.exists) {
                setNotFound(true);
            }
            dispatch(gotArticles({ [doc.id]: { ...doc.data(), exists: doc.exists } }));
        } catch (e) {
            setError(true);
        }
    };

    if (!state.articles[articleId]) {
        if (!loading) {
            getArticle();
            setLoading(true);
        }
        return <CircularProgress className={classes.loading} />
    }
    
    if (loading) {
        setLoading(false);
    }

    const article = state.articles[articleId];
    return (
        <div className={classes.wrapper}>
            <Link to="/blog">
                <ArrowBackIos className={classes.buttonBack} />
            </Link>
            <Paper>
                <div id="article">
                    <Paper
                        className={classes.article}
                        dangerouslySetInnerHTML={{ __html: article.content}}
                    />
                </div>
                <Divider />
                <Button
                    className={classes.buttonLike}
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                        const db = state.fire.firestore();
                        db.collection('blog')
                            .doc(articleId)
                            .update({ likes: state.articles[articleId].likes+1 })
                            .catch(e => setError(true));
                        dispatch(actionLike(articleId));
                    }}
                >
                    {(article.likes) ? article.likes : null} ❤️ 
                </Button>
            </Paper>
        </div>
    );
};

export default Article;