import React, { useState, useRef, useEffect } from 'react';
import {
    Paper,
    makeStyles,
} from '@material-ui/core';
import { useSteam } from '../../utils/initFirebase';
import { Bar } from './toolbar/bar';
import { Save } from './save';
import Delete from './delete';
import { ArrowBackIos } from '@material-ui/icons';


const useStyles = makeStyles(theme => ({
    paper: {
        maxWidth: '942px',
        margin: '50px auto',
    },
    typeArea: {
        maxWidth: '942px',
        padding: '50px',
    },
    buttonActions: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '40px',
    },
    buttonBack: {
        marginRight: '930px',
        marginLeft: 'auto',
    }
}));

const Editor = (props) => {
    const classes = useStyles();

    const [state] = useSteam();
    const { params } = props.match;

    useEffect(() => {
        if (params.id)  {
            document.getElementById('editor-area').innerHTML = state.articles[params.id].content;
        }
    }, [])

    const editRef = useRef();
    const [text, setText] = useState((params.id) ? state.articles[params.id].content : '');
    
    return (
        <div>
            <ArrowBackIos 
                className={classes.buttonBack}
                onClick={() => props.history.push('/admin/blog')}
            />
            <Bar />
            <Paper className={classes.paper}>
                <div 
                    id="editor-area" 
                    onInput={(e) => {
                        const html = editRef.current.innerHTML.replace(/&nbsp;|\u202F|\u00A0/g, ' ');
                        if (html !== text) {
                            setText(html);
                        }
                    }}
                    onBlur={(e) => false}
                    className={classes.typeArea}
                    ref={editRef}
                    contentEditable
                />
            </Paper>
            <div className={classes.buttonActions}>
                <Save 
                    content={text} 
                    id={params.id} 
                />
                <Delete 
                    id={params.id} 
                    history={props.history} 
                />
            </div>
        </div>
    );
};

export default Editor;