import React from 'react';
import {
    Typography, makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    successText: {
        margin: '20px auto',
        textAlign: 'center',
    }
}));

const SubSuc = (props) => {
    const classes = useStyles();
    return (
        <div>
            <Typography 
                className={classes.successText} 
                variant="h1"
            >
                Success
            </Typography>
            <Typography 
                className={classes.successText} 
                variant="h2"
            >
                Thanks for subscribing to my blog newsletter!
            </Typography>
        </div>
    );
};

export default SubSuc;