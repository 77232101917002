import firebase from 'firebase';
import React, { createContext, useReducer, useContext } from 'react';

const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
};

export const GOT_ARTICLES = 'GOT_ARTICLES';
export const DELETED_ARTICLE = 'DELETED_ARTICLE';
export const SET_USER = 'SET_USER';
export const ADMIN_LOGIN = 'ADMIN_LOGIN';
export const LIKE = 'LIKE';
export const actionLike = (id) => ({ type: LIKE, id })
export const setUser = (user) => ({ type: SET_USER, user });
export const gotArticles = (articles) => ({ type: GOT_ARTICLES, articles });
export const deletedArticle = (id) => ({ type: DELETED_ARTICLE, id });

const reducer = (state, action)  => {
    switch (action.type) {
        case SET_USER: {
            return { ...state, user: action.user };
        }
        case ADMIN_LOGIN: {
            return { ...state, adminLogin: true } ;
        }
        case GOT_ARTICLES: {
            let articles =  { ...state.articles };

            for (let id in action.articles) {
                articles[id] = action.articles[id];
            }

            return { ...state, articles, allArticles: true };
        }
        case LIKE: {
            const articles = { ...state.articles };
            articles[action.id].likes++;
            return { ...state, articles };
        }
        case DELETED_ARTICLE: {
            const articles = Object.entries(state.articles).map((entry) => entry[0] !== action.id );
            return { ...state, articles };
        }
        default: {
            return state;
        }
    }
}

firebase.initializeApp(config);

const state = {
    fire: firebase,
    articles: {},
    allArticles: false
};

export const StateContext = createContext();
export const useSteam = () => useContext(StateContext);
export const StateManager = ({ children }) => {
    return (
        <StateContext.Provider value={useReducer(reducer, state)}>
            {children}
        </StateContext.Provider>
    );
}