import React, { useReducer } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
    Paper,
    Typography,
    Divider,
    List,
    ListSubheader,
    ListItem,
    ListItemIcon,
    ListItemText,
    Collapse,
} from '@material-ui/core';
import {
    ExpandMore,
    ExpandLess,
} from '@material-ui/icons';
import Timeline from './timeline';

const useStyles = makeStyles(theme => ({
    wrapper: {
        [theme.breakpoints.up('sm')]: {
            margin: '100px auto',
            maxWidth: '942px',
        },
        margin: '50px auto',
    },
    list: {
        [theme.breakpoints.up('sm')]: {
            width: '40%',
        },
    },
    headerText: {
        padding: '20px',
    },
    infoText: {
        padding: '20px',
    },
    paper: {
        marginTop: '30px',
        marginBottom: '30px',
    },
    wrapperList: {
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            justifyContent: 'space-evenly',
            flexWrap: 'wrap',
        },
    },
}));

const JS_OPEN = 'JS_OPEN';
const MARKUP_OPEN = 'HTML_OPEN';
const PERSONAL_OPEN = 'PERSONAL_OPEN';
const SOCIAL_OPEN = 'SOCIAL_OPEN';
const LANGUAGE_OPEN = 'LANGUAGE_OPEN';
const JS_FRAMEWORK_OPEN = 'JS_FRAMEWORK_OPEN';
const CSS_FRAMEWORK_OPEN = 'CSS_FRAMEWORK_OPEN';
const GRAPHQL_OPEN = 'GRAPHQL_OPEN';
const PY_FRAMEWORK_OPEN = 'PY_FRAMEWORKS_OPEN';
const SQL_OPEN = 'SQL_OPEN';
const MONGO_OPEN = 'MONGO_OPEN';
const REDHAT_OPEN = 'REDHAT_OPEN'


const actions = {
    'js': JS_OPEN,
    'markup': MARKUP_OPEN,
    'personal': PERSONAL_OPEN,
    'social': SOCIAL_OPEN,
    'language': LANGUAGE_OPEN,
    'jsframework': JS_FRAMEWORK_OPEN,
    'cssframework': CSS_FRAMEWORK_OPEN,
    'graphql': GRAPHQL_OPEN,
    'pyframework': PY_FRAMEWORK_OPEN,
    'sql': SQL_OPEN,
    'mongo': MONGO_OPEN,
    'redhat': REDHAT_OPEN,
};

const swapObj = (obj) => {
    const ret = {};
    Object.keys(obj).forEach((key) => {
        ret[obj[key]] = key;
    });
    return ret;
};

const actionsReverse = swapObj(actions);

const reducer = (state, action) => {
    return { ...state, [actionsReverse[action]]: !state[actionsReverse[action]] };
    /*
    switch (action) {
        case JS_OPEN: {
            return { ...state, js: !state.js };
        }
        case MARKUP_OPEN: {
            return { ...state, markup: !state.markup };
        }
        case SOCIAL_OPEN: {
            return { ...state, social: !state.social };
        }
        case LANGUAGE_OPEN: {
            return { ...state, language: !state.langauge };
        }
        default: {
            return state;
        }
    }
    */
};

const initialOpenState = {
    js: false,
    markup: false,
};

/*
const softSkills = [
    {
        icon: 'P',
        text: 'Personal',
        open: 'personal',
        expansion: [
            {
                icon: 'C',
                text: 'Curios',
            },
            {
                icon: 'D',
                text: 'Determined',
            },
            {
                icon: 'E',
                text: 'Extrovert',
            },
            {
                icon: 'K',
                text: 'Kind',
            },
        ],
    },
    {
        icon: 'S',
        text: 'Social',
        open: 'social',
        expansion: [
            {
                icon: 'F',
                text: 'Friendly',
            },
            {
                icon: 'L',
                text: 'Teaching & Learning'
            },
            {
                icon: 'T',
                text: 'Teamworker'
            },
        ]
    },
    {
        icon: 'L',
        text: 'Language',
        open: 'language',
        expansion: [
            {
                icon: 'DE',
                text: 'German (native)'
            },
            {
                icon: 'EN',
                text: 'English (fluent)'
            }
        ]
    },
];
*/

/*
const office = [
    {
        icon: 'MS',
        text: 'Microsoft Office Suite',
    },
    {
        icon: 'G',
        text: 'Google Drive related'
    },
];
*/

const frameworkSkills = [
    {
        icon: 'JS',
        text: 'JS Frameworks',
        open: 'jsframework',
        expansion: [
            {
                icon: 'R',
                text: 'React',
            },
            {
                icon: 'Rx',
                text: 'Redux',
                open: 'redux',
            },
            {
                icon: 'V',
                text: 'VueJS',
            },
        ],
    },
    {
        icon: 'CSS',
        text: 'CSS Frameworks',
        open: 'cssframework',
        expansion: [
            { icon: 'B', text: 'Bootstrap' },
            { icon: '@M', text: 'material-ui' },

        ],
    },
    {
        icon: 'GQ',
        text: 'GraphQL',
        open: 'graphql',
        expansion: [
            { icon: 'A', text: 'Apollo' },
            { icon: 'P', text: 'Prisma' },
        ],
    },
    {
        icon: 'Py',
        text: 'Python Frameworks',
        open: 'pyframework',
        expansion: [
            {
                icon: 'F',
                text: 'Flask',
                open: 'flask',
            },
            {
                icon: 'SQLA',
                text: 'SQL Alchemy'
            },
        ]
    },
];


const databaseSkills = [
    {
        icon: 'SQL',
        text: 'Relational Databases',
        open: 'sql',
        expansion: [
            { icon: 'M', text: 'MySQL/MariaDB' },
            { icon: 'Pgre', text: 'PostgreSQL' }
        ],
    },
    {
        icon: 'M',
        text: 'MongoDB',
        open: 'mongo',
        expansion: [
            { icon: 'N', text: 'Native client (CLI)' },
            { icon: 'JS', text: 'NodeJS driver ' },
        ],
    },
    {
        icon: 'Mn',
        text: 'Mnesia'
    }
];


const virtualizationSkills = [
    {
        icon: 'A',
        text: 'Ansible',
    },
    {
        icon: 'D',
        text: 'Docker'
    },
    {
        icon: 'V',
        text: 'HashiCorp Vagrant'
    },
];

const cloudSkills = [
    {
        icon: 'G',
        text: 'Google Cloud Platform',
        open: 'gcloud',
        /*
        expansion: [
            { icon: 'A', text: 'App Engine' },
            { icon: 'C', text: 'Compute Engine'},
            { icon: 'D', text: 'DialogFlow' }, 
            { icon: 'F', text: 'Firebase' },
        ],
        */
    },
    {
        icon: 'AWS',
        text: 'Amazon Web Services',
        open: 'aws',
        /*
        expansion: [
            { icon: 'EC', text: 'Elastic Compute' },
            { icon: 'SMS', text: 'Simple Mail Service' },
            { icon: 'SNS', text: 'Simple Notification Service' },
            { icon: '53', text: 'Route53' },
        ]
        */
    },
    {
        icon: 'AZ',
        text: 'Azure'
    }
];

const osSkills = [
    {
        icon: 'R',
        text: 'Redhat related',
        open: 'redhat',
        expansion: [
            { icon: 'C', text: 'Centos' },
            { icon: 'F', text: 'Fedora' },
            { icon: 'R', text: 'RHEL' },
        ]
    },
    {
        icon: 'W',
        text: 'Windows',
    },
    {
        icon: 'M',
        text: 'MacOS'
    }
];

const programmSkills = [
    {
        icon: 'JS',
        text: 'JavaScript',
        open: 'js',
        expansion: [
            { icon: '7', text: 'ECMA 12' },
            { icon: 'TS', text: 'TypeScript' },
            { icon: 'N', text: 'NodeJS (stdlib)' },
        ],
    },
    {
        icon: 'Py',
        text: 'Python'
    },
    {
        icon: 'EX',
        text: 'Elixir OTP/Erlang',
    },
    {
        icon: '<>',
        text: 'Markup',
        open: 'markup',
        expansion: [
            { icon: '<!>', text: 'HTML' },
            { icon: '<JS>', text: 'JSX' },
            { icon: '<?>', text: 'XML' },
            { icon: '{}', text: 'JSON' },
        ],
    },
    {
        icon: 'CSS',
        text: 'Cascade-Style-Sheet',
    },
    {
        icon: 'RS',
        text: 'Rust'
    },
    {
        icon: 'C',
        text: 'C/C++'
    },
    {
        icon: '#',
        text: 'F/C#'
    },
    {
        icon: 'Go',
        text: 'Golang'
    }
];

/*
    skills [
        {text, icon, open},
        {
            text,
            icon,
            open,
            expansion: [
                {text, icon, open, ...}
            ]
        }
    ] 
*/



const createList = (skills, dispatchOpen, openState, classes) => {
    const list = skills.map((point, index) => {
        if (point.expansion) {
            return (
                <>
                    <ListItem
                        onClick={() => dispatchOpen(actions[point.open])}
                        button
                    >
                        <ListItemIcon>
                            {point.icon}
                        </ListItemIcon>
                        <ListItemText primary={point.text} />
                        {(openState[point.open]) ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={openState[point.open]} timeout="auto" unmountOnExit>
                        <List
                            component="nav"
                            disablePadding
                        >
                            {createList(point.expansion, dispatchOpen, openState, classes)}
                        </List>
                        <Divider />
                    </Collapse>
                </>
            );
        } else {
            return (
                <ListItem
                    button
                >
                    <ListItemIcon>
                        {point.icon}
                    </ListItemIcon>
                    <ListItemText primary={point.text} />
                </ListItem>
            );
        }
    });
    return list;
};

const About = (props) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const [openState, dispatchOpen] = useReducer(reducer, initialOpenState);
    const pList = createList(programmSkills, dispatchOpen, openState, classes);
    const fList = createList(frameworkSkills, dispatchOpen, openState, classes);
    const osList = createList(osSkills, dispatchOpen, openState, classes);
    const cloudList = createList(cloudSkills, dispatchOpen, openState, classes);
    const virtList = createList(virtualizationSkills, dispatchOpen, openState, classes);
    const dbList = createList(databaseSkills, dispatchOpen, openState, classes);
    // const softList = createList(softSkills, dispatchOpen, openState, classes);
    return (
        <div className={classes.wrapper}>
            <Paper className={classes.paper}>
                <Typography
                    variant="h4"
                    component="h3"
                    className={classes.headerText}
                >
                    About
                </Typography>
                <Divider />
                <Typography
                    component="p"
                    className={classes.infoText}
                >
                    Here some information about my skills
                    <br />
                    and things I did in my life:
                </Typography>
            </Paper>
            <Paper>
                <div className={classes.wrapperList}>
                    <List
                        className={classes.list}
                        component="nav"
                        aria-labelledby="P-Language"
                        subheader={
                            <ListSubheader component="div">
                                P-Language
                            </ListSubheader>
                        }
                    >
                        {pList}
                    </List>
                    <List
                        className={classes.list}
                        component="nav"
                        aria-labelledby="Frameworks"
                        subheader={
                            <ListSubheader component="div">
                                Frameworks
                            </ListSubheader>
                        }
                    >
                        {fList}
                    </List>
                </div>
                <div className={classes.wrapperList}>
                    <List
                        className={classes.list}
                        component="nav"
                        aria-labelledby="Cloud"
                        subheader={
                            <ListSubheader component="div">
                                Cloud
                            </ListSubheader>
                        }
                    >
                        {cloudList}
                    </List>
                    <List
                        className={classes.list}
                        component="nav"
                        aria-labelledby="Virtualization"
                        subheader={
                            <ListSubheader component="div">
                                Virtualization
                            </ListSubheader>
                        }
                    >
                        {virtList}
                    </List>
                </div>
                <div className={classes.wrapperList}>
                    <List
                        className={classes.list}
                        component="nav"
                        aria-labelledby="OS"
                        subheader={
                            <ListSubheader component="div">
                                OS
                            </ListSubheader>
                        }
                    >
                        {osList}
                    </List>
                    <List
                        className={classes.list}
                        component="nav"
                        aria-labelledby="DataBase"
                        subheader={
                            <ListSubheader component="div">
                                DataBase
                            </ListSubheader>
                        }
                    >
                        {dbList}
                    </List>
                </div>
            </Paper>
            <Paper>
                <div className={classes.wrapperList}>
                </div>
            </Paper>
            <Timeline />
        </div>
    );
};
/*
    <div className={classes.col}>
        <List
            component="nav"
            aria-labelledby="Frameworks"
            subheader={
                <ListSubheader component="div">
                    Frameworks
                </ListSubheader>
            }
        >
            {fList}
        </List>
        <List
            component="nav"
            aria-labelledby="DataBase"
            subheader={
                <ListSubheader component="div">
                    DataBase 
                </ListSubheader>
            }
        >
            {dbList}
        </List>
        <List
            component="nav"
            aria-labelledby="Virtualization"
            subheader={
                <ListSubheader component="div">
                    Virtualization
                </ListSubheader>
            }
        >
            {virtList}
        </List>
    </div>
    <div className={classes.col}>
        <List
            component="nav"
            aria-labelledby="P-Language"
            subheader={
                <ListSubheader component="div">
                    P-Language
                </ListSubheader>
            }
        >
            {pList}
        </List>
        <List
            component="nav"
            aria-labelledby="Cloud"
            subheader={
                <ListSubheader component="div">
                    Cloud
                </ListSubheader>
            }
        >
            {cloudList}
        </List>
        <List
            component="nav"
            aria-labelledby="OS"
            subheader={
                <ListSubheader component="div">
                    OS
                </ListSubheader>
            }
        >
            {osList}
        </List>
    </div>
*/
/*
                {/*
                
                */


/*
    <List
        component="nav"
        aria-labelledby="Software skills"
        subheader={
            <ListSubheader component="div">
                Software skills
            </ListSubheader>
        }
    >
        {list}
    </List>
    <List
        component="nav"
        aria-labelledby="software skills"
        subheader={
            <ListSubheader component="div">
                Software skills
            </ListSubheader>
        }
    >
        <ListItem 
            onClick={() => setOpen(!open)}
            button
        >
            <ListItemIcon>
                <FontDownload />
            </ListItemIcon>
            <ListItemText primary="Languages" />
            {(open) ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                <ListItem
                button 
                >
                    <ListItemIcon>
                        JS
                    </ListItemIcon>
                    <ListItemText primary="JavaScript" />
                </ListItem>
            </List>
        </Collapse>
    </List>
*/
export default About;