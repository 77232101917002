import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import emojiLib from 'node-emoji';

const useStyles = makeStyles(random => ({
    guessEmoji: {
        textAlign: 'center',
        fontSize: '3em',
        fontFamily: '-apple-system, system-ui, BlinkMacSystemFont, \'Segoe UI\', Roboto, \'Helvetica Neue\', Ubuntu, Arial, sans-serif',
    },
    guessTitle: {
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: '1.2em',
    },
    guessDiv: {
        display: 'flex',
        alignItems: 'center',
    },
    guessButton: {
        marginLeft: '20px',
    },
    solutionButton: {
        margin: '7px auto auto auto',
    },
    rightButton: {
        borderColor: 'green',
        color: 'green',
        margin: 'auto',
    },
    wrongButton: {
        margin: 'auto',
    },
    solution: {
        display: 'flex',
        alignItems: 'center',
    },
    replay: {
        marginLeft: '20px',
        color: 'orange',
        borderColor: 'orange',
    },
    score: {
        fontStyle: 'italic',
        marginTop: '10px',
    },
}));
/*
const createRandomEmoji = () => {
    const generatedEmo = emoji.random();
}
*/

const checkGuess = (guess, emoji, setWin, score, setScore) => {
    if (guess === emoji.key) {
        setWin(true);
        setScore(score+1);
    } else {
        const key = emoji.key.replace('_', ' ').replace('-', ' ');
        if (guess.toLowerCase() === key) {
            setWin(true) 
            setScore(score+1);
        } else {
            setWin(false);
        }
    }
};

const Emo = (props) => {
    const classes = useStyles();
    const [win, setWin] = useState(null);
    const [score, setScore] = useState(0);
    const [emoji, setEmoji] = useState(emojiLib.random());
    const [guess, setGuess] = useState('');
    const [solution, setSolution] = useState(null);
    const [winDiv, setWinDiv] = useState(null);
    useEffect(() => {
        //const solutionModified = emoji.key.replace('_', ' ').replace('-', ' ');
        if (win === true) {
            const div = (
                <Button
                    className={clsx(classes.rightButton, classes.solutionButton)}
                    variant="outlined"
                    color="primary"
                >
                    {emojiLib.get('heavy_check_mark')}
                </Button>
            );
            setSolution(emoji.key);
            setWinDiv(div);
        } else if (win === false) {
            const div = (
                <Button
                    className={clsx(classes.wrongButton, classes.solutionButton)}
                    variant="outlined"
                    color="secondary"
                >
                   {emojiLib.get('x')} 
                </Button>
            );
            setSolution(emoji.key);
            setWinDiv(div);
        }
    }, [win, classes.rightButton, classes.solutionButton, classes.wrongButton, emoji.key]);
    return (
        <Card>
            <CardContent>
                <Typography 
                    className={classes.guessTitle} 
                    color="textSecondary"
                    gutterBottom
                >
                    Guess the emoji
                    <span role="img" aria-label="hand">✋</span>
                </Typography>
                <Typography
                    className={classes.guessTitle}
                    gutterBottom
                >
                    {solution}
                </Typography>

                <Typography className={classes.guessEmoji}>
                    <span role="img" aria-label="Guess emoji">{emoji.emoji}</span>
                </Typography>
                <div className={classes.solution}>
                    {winDiv}
                </div>
                <div className={classes.guessDiv}>
                    <TextField
                        label="Your guess"
                        value={guess}
                        onChange={(event) => setGuess(event.target.value)}
                        onKeyPress={(event) => {
                            if (event.key === 'Enter') checkGuess(guess, emoji, setWin, score, setScore);
                        }}
                        disabled={(win !== null)}
                    />
                    {
                        (win === null) ? 
                            <Button
                                className={classes.guessButton}
                                variant="outlined"
                                color="primary"
                                onClick={(event) =>  checkGuess(guess, emoji, setWin, score, setScore)}
                            >
                                Check
                            </Button>
                        :
                            <Button
                                className={classes.replay}
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                    setWin(null);
                                    setSolution(null);
                                    setWinDiv(null);
                                    setGuess('');
                                    setEmoji(emojiLib.random());
                                }}
                            >
                                Replay
                            </Button>
                    }
                </div>
                <div className={classes.score}>Score: {score}</div>
            </CardContent>
        </Card>
    );
};

export default Emo;