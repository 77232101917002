import React, { useState, useEffect } from 'react';
import {
    Button,
    makeStyles,
    TextField,
    MenuItem,
    Select,
    OutlinedInput,
} from '@material-ui/core';
import {
    FormatBold,
    FormatItalic,
    FormatUnderlined,
    FormatAlignLeft,
    FormatAlignRight,
    FormatAlignCenter,
    AddPhotoAlternate,
    InsertLink,
    FormatStrikethrough,
    FormatShapes,
    FormatListNumberedRounded,
    FormatListBulleted,
} from '@material-ui/icons';

const editCmd = (cmd, value) => {
    document.execCommand(cmd, false, value);
    document.getElementById('editor-area').focus();
}

const useStyles = makeStyles(theme => ({
    inputEdit: {
        [theme.breakpoints.up('sm')]: {
            position: 'absolute',
            left: '20px',
            bottom: '20px',
        },
        display: 'block',
        margin: 'auto',
    },
    inputFont: {
        width: '125px',
        marginLeft: '20px',
    },
    urlWrapper: {
        maxWidth: '300px',
        margin: '20px auto',
        display: 'flex',
    },
    inputUrl: {
        width: '150px',
    },
    inputFontSize: {
        width: '25px',
        margin: '20px',
    },
    buttonFontColor: {
        margin: '20px',
    },
}));


export const FontVariant = (props) => {

    const fontVariantOptions = ['Normal', 'Title', 'Subtitle'];

    const classes = useStyles();
    const [fontVariant, setFontVariant] = useState('Normal');

    useEffect(() => {
        if (fontVariant === 'Title') {
            document.execCommand('fontSize', false, 6);
        } else if (fontVariant === 'Subtitle') {
            document.execCommand('fontSize', false, 4);
            document.execCommand('italic');
            document.execCommand('foreColor', false, '#c0c0c0');
        }

    }, [fontVariant]);

    return (
        <TextField
            select
            className={classes.inputFont}
            label="Variant"
            value={fontVariant}
            onChange={(e) => setFontVariant(e.target.value)}
            SelectProps={{
                helperText: 'Select a design for font',
                margin: 'normal',
            }}
        >
            {fontVariantOptions.map((option, array) => {
                return (
                    <MenuItem
                        key={option + String(array)}
                        value={option}
                    >
                        {option}
                    </MenuItem>
                );
            })}
        </TextField>
    );
}

export const FontFamily = (props) => {

    const [show, setShow] = useState(false);
    const [family, setFamily] = useState('Work Sans');

    useEffect(() => {
        document.getElementById('editor-area').focus();
        document.execCommand('fontName', false, 'Work Sans');
    }, []);

    const cmd = () => {
        document.getElementById('editor-area').focus();
        document.execCommand('fontName', false, family);
        setShow(false);
    }

    return (
        <>
            <Button>
                <FormatShapes
                    onClick={() => setShow(true)}
                />
            </Button>
            {
                (show)
                    ?
                    <EnterCmdValue
                        setValue={setFamily}
                        value={family}
                        label="Font Family"
                        command={cmd}
                    />
                    :
                    null
            }

        </>
    );
}

export const FontStyle = (props) => {
    return (
        <>
            <Button
                onClick={() => editCmd('bold')}
            >
                <FormatBold />
            </Button>
            <Button
                onClick={() => editCmd('italic')}
            >
                <FormatItalic />
            </Button>
            <Button
                onClick={() => editCmd('underline')}
            >
                <FormatUnderlined />
            </Button>
        </>
    );
}

export const Alignment = (props) => {
    return (
        <>
            <Button
                onClick={() => editCmd('justifyLeft')}
            >
                <FormatAlignLeft />
            </Button>
            <Button
                onClick={() => editCmd('justifyRight')}
            >
                <FormatAlignRight />
            </Button>
            <Button
                onClick={() => editCmd('justifyCenter')}
            >
                <FormatAlignCenter />
            </Button>
        </>
    );
}

export const AddImage = (props) => {
    const [show, setShow] = useState(false);
    const [url, setUrl] = useState('');
    const cmd = () => {
        const html = `<img src="${url}" style="max-width:100%;height:auto;" alt="blog-image" />`;
        document.getElementById('editor-area').focus();
        document.execCommand('insertHTML', false, html);
        setShow(false);
        setUrl('');
    };
    return (
        <>
            <Button
                onClick={() => setShow(true)}
            >
                <AddPhotoAlternate />
            </Button>
            {
                (show)
                    ?
                    <EnterCmdValue
                        label="Image URL"
                        command={cmd}
                        value={url}
                        setValue={setUrl}
                    />
                    :
                    null
            }
        </>
    );
}

export const CreateLink = (props) => {
    const [show, setShow] = useState(false);
    const [url, setUrl] = useState('');
    const cmd = () => {
        document.getElementById('editor-area').focus();
        document.execCommand('createLink', false, url);
        setShow(false);
        setUrl('');
    }
    return (
        <>
            <Button
                onClick={() => setShow(true)}
            >
                <InsertLink />
            </Button>
            {
                (show)
                    ?
                    <EnterCmdValue
                        label="Link URL"
                        command={cmd}
                        value={url}
                        setValue={setUrl}
                    />
                    :
                    null
            }
        </>
    );
}

export const HRLine = (props) => {
    return (
        <Button
            onClick={() => document.execCommand('insertHTML', false, '<hr>')}
        >
            <FormatStrikethrough />
        </Button>
    );
};

export const FontSize = (props) => {
    const [fontSize, setFontSize] = useState('5');
    const sizes = ['1', '2', '3', '4', '5', '6', '7'];
    useEffect(() => {
        document.getElementById('editor-area').focus();
        console.log(document.execCommand('fontSize', false, fontSize));
    }, [fontSize])
    return (
        <>
            <Select
                value={fontSize}
                onChange={(e) => setFontSize(e.target.value)}
                input={<OutlinedInput name="Size" />}
            >
                {sizes.map((s, _, arr) => <MenuItem key={s + arr} value={s}>{s}</MenuItem>)}
            </Select>
        </>
    );
};

export const FontColor = (props) => {
    const classes = useStyles();
    const [show, setShow] = useState(false);
    const [color, setColor] = useState('');
    const cmd = (enter) => {
        document.getElementById('editor-area').focus();
        document.execCommand('foreColor', false, color);
        setShow(false);
    };
    return (
        <>
            <Button
                className={classes.buttonFontColor}
                style={{
                    color,
                    borderColor: color,
                }}
                onClick={() => setShow(true)}
            >
                Color
            </Button>
            {
                (show)
                    ?
                    <EnterCmdValue
                        value={color}
                        setValue={setColor}
                        command={cmd}
                    />
                    :
                    null
            }
        </>
    );
}

export const OrderedList = (props) => {
    return (
        <Button
            onClick={() => {
                document.getElementById('editor-area').focus();
                document.execCommand('insertOrderedList');
            }}
        >
            <FormatListNumberedRounded />
        </Button>
    );
};

export const BulletList = (props) => {
    return (
        <Button
            onClick={() => {
                document.getElementById('editor-area').focus();
                document.execCommand('insertOrderedList');
            }}
        >
            <FormatListBulleted />
        </Button>
    );
}

export const EnterCmdValue = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.inputEdit}>
            <TextField
                label={props.label}
                value={props.value}
                onChange={(e) => props.setValue(e.target.value)}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        props.command();
                    }
                }}
            />
            <Button
                variant="outlined"
                color="primary"
                onClick={() => props.command()}
            >
                Apply
            </Button>
        </div>
    );
};