import React from 'react';

const NotFound = () => {
    return (
        <div>
            <h4>404 Not Found</h4>
            <a href="/home">Back Home</a>
        </div>
    );
}

export default NotFound;