import React, { useState } from 'react';
import { Slider } from '@material-ui/core';
import { Typography, Paper, Card, CardContent } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    list: {
        paddingLeft: '16px',
    },
    sliderTimeline: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.8em',
        },
    },
    timeline: {
        paddingTop: '20px',
        marginTop: '30px',
    },
}));

const marks = [
    {
        value: 10,
        label: '15'
    },
    {
        value: 30,
        label: '01/16'
    },
    {
        value: 35,
        label: '02/16',
    },
    {
        value: 40,
        label: '11/17'
    },
    {
        value: 45,
        label: '07/18',
    },
    {
        value: 50,
        label: '11/18'
    },
    {
        value: 55,
        label: '05/19'
    },
    {
        value: 60,
        label: '11/19'
    },
    {
        value: 70,
        label: '08/20'
    },
    {
        value: 90,
        label: '...'
    }
];

const Timepoint = (props) => {
    let list = null;
    if (props.list) {
        list = props.list.map((item) => {
            return (
                <li>
                    {item}
                </li>
            );
        });
    }
    let text = null
    if (props.lines) {
        text = props.lines.map((line) => <span>{line}<br /></span>);
    }
    const listClass = (props.classes) ? props.classes.list : null;
    return (
        <Card>
            <CardContent>
                <Typography variant="h6" component="p">
                    {props.header}
                </Typography>
                <Typography>
                    {text}
                </Typography>
                <Typography>
                    <ul
                        className={listClass}>
                        {list}
                    </ul>
                </Typography>
            </CardContent>
        </Card>
    );
}

const highschool = {
    header: 'Highschool',
    lines: [
        'Start of my high school time at JBS.',
        'It is famous for its support of artistic',
        'talents and awards at young research projects.',
    ],
};

const internship = {
    header: 'Internship',
    lines: [
        'Internship at iteratec GmbH.',
        'Due to extra-curricular activities',
        'in discrete mathematics I was able',
        'to get an internship at the company',
        'who provides customized solutions',
        'for other businesses.',
    ],
};

const endofinternship = {
    header: 'End of internship',
    lines: [
        'At the end of my internship',
        'I got valuable insights in',
        'agile workflows and methods.',
        'Also I learned more about',
        'current trends in technology',
        'I built with python a website which',
        'mapped the ansible infrastructure',
        '(profiles, roles) to a wiki',
    ],
};

const itassistant = {
    header: 'DevOps assistant',
    lines: [
        'While doing my final year in high school',
        'I was looking for a job in the industry',
        'that would help me to develop a first taste.',
        'At that time G+J was on the search for a',
        'working student who could assist',
        'in their IT Administration.',
        'My github projects helped me get',
        'this position at a company with products like:',
    ],
    list: [
        'National Geographic',
        'Gala',
        'Brigitte',
        'Stern',
    ],
};

const endofhighschool = {
    header: 'End of Highschool',
    lines: [
        'I graduated from highschool and',
        'got an award for exceptional perfomance',
        'in the field of philosophy granted by',
        'the German organization of philosophy',
    ],
};

const endofgj = {
    header: 'End of IT Assistant',
    lines: [
        'I was ending my contract with G+J',
        'I learned so much from the company',
        'and my co-workers, whom I was lucky to meet.',
        'During my time there, I developed python',
        'scripts and orchistrated parts',
        'of the network infrastructure.',
    ],
};

const pristineBNB = {
    header: 'Software Developer PristineBNB',
    lines: [
        'Arrived in the Down Under.',
        'Working for a hospitality startup.',
        'Enriching experience working with',
        'an international work community',
        'Expanded website templates and',
        'connecting to external APIs like Expedia.',
    ]
}

const ZECH = {
    header: 'Working student Digital transformation',
    lines: [
        'Back in Germany started studying Computer Science',
        'at the TUHH. Still collecting work experience under',
        'the ZECH, (re-)building both new and old generation.',
        'Helped with the transition to O365, Azure services',
        'with C#/F#, and .NET stack. It helped to present',
        'technological themes to non-techs and increasing acceptance.'
    ]
}

const AKRA = {
    header: 'Working student Software Development',
    lines: [
        'From my initiative and the need to dive deeper',
        'into development started working at AKRA with the',
        'OTP stack. Collected best practice techniques and',
        'how to work the agile way close to the customer.',
        'Mainly Elixir/Vue/Rails with GraphQL on a CI/CD',
        'GCP infrastructure.'
    ]
}

const currentTime = {
    header: 'What\'s next?',
    lines: [
        'Whatever happens, happens.'
    ],
}

const Timeline = (props) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const [text, setText] = useState(0);
    highschool.classes = classes;
    internship.classes = classes;
    endofinternship.classes = classes;
    itassistant.classes = classes;
    endofhighschool.classes = classes;
    endofgj.classes = classes;
    const valueText = (value) => {
        switch (value) {
            case 9: {
                setText(null);
                break;
            }
            case 10: {
                setText(highschool);
                break;
            }
            case 30: {
                setText(internship);
                break;
            }
            case 35: {
                setText(endofinternship);
                break;
            }
            case 40: {
                setText(itassistant);
                break;

            }
            case 45: {
                setText(endofhighschool);
                break;
            }
            case 50: {
                setText(endofgj);
                break;
            }
            case 55: {
                setText(pristineBNB);
                break;
            }
            case 60: {
                setText(ZECH);
                break;
            }
            case 70: {
                setText(AKRA);
                break;
            }
            case 90: {
                setText(currentTime);
                break;
            }
            default: {
                return value;
            }
        }
    };
    return (
        <Paper className={classes.timeline}>
            <Slider
                className={classes.sliderTimeline}
                defaultValue={10}
                marks={marks}
                getAriaValueText={valueText}
                aria-labelledby="timeline"
                classes={{ markLabel: classes.sliderTimeline }}
            />
            <Timepoint {...text} />
        </Paper>
    );
};

export default Timeline;