import React, { useState } from 'react';
import {
    AppBar,
    Toolbar,
    IconButton,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Menu } from '@material-ui/icons';
import MobileMenu from './mobile_menu';

const useStyleNav = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    title: {
        flexGrow: 1,
    },
}));

const MobileNavBar = (props) => {
    const classes = useStyleNav();
    const [menuState, setMenu] = useState(false);
    return (
        <div>
            <AppBar
                position="static"
                color="default"
            >
                <Toolbar color="inherit" className={classes.root}>
                    <IconButton
                        edge="start"
                        className={''}
                        color="inherit"
                        aria-label="menu"
                        onClick={() => {
                            setMenu(!menuState);
                        }}
                    >
                        <Menu />
                    </IconButton>
                    <Typography variant="h6">
                        NJL
                </Typography>
                </Toolbar>
            </AppBar>
            <MobileMenu
                menuState={menuState}
                setMenu={setMenu}
            />
        </div>
    );
}

export default MobileNavBar;